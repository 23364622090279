<template>
  <div>
    <internal-document-container
      v-for="document in documents"
      :document="document"
      v-bind:key="document"
      class="pagebreak"
    />
    <error-modal v-if="error" :error="error" @cancel="error = null" @cancelAll="error = null" />
  </div>
</template>

<script>
import { retrieveInternalDocument, retrieveDealerPack } from "../../../api/v2/InternalDocuments.js";
import InternalDocumentContainer from "@/views/v2/InternalDocuments/InternalDocumentContainer.vue";
import ErrorModal from "@/components/Modals/v2/ErrorModal.vue";

export default {
  name: "InternalDocument",
  components: {
    InternalDocumentContainer,
    ErrorModal
  },
  data: function () {
    return {
      documents: [],
      error: null
    };
  },
  mounted: function () {
    if (this.$route.name == "InternalDocument") {
      retrieveInternalDocument(this.$route.params.id)
        .then(response => {
          this.documents = [response];
        })
        .catch(error => {
          this.error = error;
        });
    } else if (this.$route.name == "DealerPack") {
      retrieveDealerPack(this.$route.params.id)
        .then(response => {
          this.documents = response.documents;
        })
        .catch(error => {
          this.error = error;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.pagebreak {
  page-break-after: always;
}
</style>
