<template>
  <div>
    <div id="document-container" class="flex-column">
      <img id="document-img" :src="document.document" />
    </div>
  </div>
</template>

<script>
export default {
  name: "InternalDocumentContainer",
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  methods: {
    onLoad: function () {
      this.$emit("loaded");
    }
  }
};
</script>

<style lang="scss" scoped>
@media print {
  body {
    width: 21cm;
    height: 29.7cm;
    margin: 30mm 45mm 30mm 45mm;
  }
}

#document-container {
  width: 21cm;
  height: 29.7cm;
}

#document-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
